// @flow
import React from 'react';
import styled from 'styled-components';
import Layout from '../../components/layout';
import G from '../../components/glossary-link';
import Link from '../../components/link';
import type { FrontMatter } from '../../utils/types';
import { withFrontMatter } from '../../components';
import route from '../../routes';
import allLinks from '../../data/all-links';

const Header = styled.p`
  font-weight: bold;
`;

const Added = styled.p`
  background-color: rgb(236, 253, 240);
`;

const Removed = styled.p`
  background-color: rgb(251, 233, 235);
`;

type Props = {
  frontMatter: FrontMatter,
};

class Blog_2020_04_25_Aca_Skills_Checklists_Updates extends React.Component<Props> {
  render() {
    const { frontMatter } = this.props;

    return (
      <Layout frontMatter={frontMatter} toc>
        <p>
          The <G>American Canyoneering Association</G> recently updated all
          their{' '}
          <Link to="https://www.canyoneering.net/aca-skills-checklists-refinement/?fbclid=IwAR1iL7L3pgqNZi7Yxr81iTmslIZVTzKQ0KVtERcUswagzy-d-QRGhjG3hcA">
            Skills Checklists
          </Link>
          . Let's nerd out and see what changed.
        </p>
        <h2>Core Skills Checklist</h2>
        <Header>Logistics and Safety</Header>
        <Added>
          Added: Demonstrate how to activate an SOS on a{' '}
          <G>Personal Locator Beacon</G> (PLB). (Moved from{' '}
          <Link to={route.aca_cl1_skills_checklist + '#emergency-response'}>
            CL1 - Emergency Response
          </Link>
          )
        </Added>
        <Header>Leave No Trace Ethics</Header>
        <Added>
          Added: Understand and apply techniques to avoid <G>rope grooves</G>
          /damage in soft rock.
        </Added>
        <Header>Basic Knots</Header>
        <Added>
          Added: Tie and inspect; <G>Valdotain Tresse</G> (tied with a{' '}
          <G>VT Prusik</G> or equivalent). (Moved from{' '}
          <Link to={route.aca_aspirant_skills_checklist + '#knot-craft'}>
            Aspirant - Knot Craft
          </Link>
          )
        </Added>
        <Header>Intro to Anchors and Rigging</Header>
        <Added>
          Added: Identify, assess and mitigate basic{' '}
          <Link to={route.page_pull}>rope retrieval challenges</Link> (crossed
          ropes) including minimizing or avoiding rope grooves/damage to soft
          rock.
        </Added>
        <Added>
          Added: Prep ropes for retrieval & successfully retrieve rope and prep
          for transport (Coil or <G id="rope-bag">Bag</G>).
        </Added>
        <Header>Signals / Communication</Header>
        <p>Renamed from just "Signals"</p>
        <Removed>Removed: Understand and use proper whistle signals.</Removed>
        <Header>Climbing, Spotting, Handlines</Header>
        <p>Renamed from "Climbing, Spotting, Belaying, Handlines"</p>
        <Removed>
          Removed: <G>Belay</G> a climber; climbing up,{' '}
          <G id="down-climbing">climbing down</G>, using <G>rope</G> from fixed{' '}
          <G>anchor</G>, using rope from <G id="meat-anchor">human anchor</G>.
        </Removed>
        <Header>Rappelling, Belaying, Self-Rescue</Header>
        <Added>
          Added: Understand and explain the risk and tasks associated with being
          the first rappeller.
        </Added>
        <Added>
          Added: Rappel using different the starting positions that include a)
          traditional (standing) b) <G id="sit-start">sitting</G> c) soft start
          (body friction/<G id="smear">smearing</G>); explain when such a start
          should be used.
        </Added>
        <Added>
          Added: Demonstrate proper belay commands / verbal communication
          techniques.
        </Added>
        <Added>
          Added: Rappel with pack on and off. Describe scenarios for both
          situations.
        </Added>
        <Added>
          Added: Perform controlled rappel up to 150'+ adding friction
          mid-rappel; communication - whistles or radios; hanging packs.
        </Added>
        <Header>Swimming, Jumping, Water Slides</Header>
        <Added>
          Added: Create and use flotation with gear available. (Moved from{' '}
          <Link
            to={
              route.aca_aspirant_skills_checklist +
              '#swimming-jumping-waterfalls'
            }
          >
            Aspirant - Swimming, Jumping, Waterfalls
          </Link>
          )
        </Added>
        <Added>
          Added: Safely perform water depth check; AKA "<G>dipstick</G>". ***No
          jumping!***
        </Added>
        <Added>
          Added: Jumping into water (pool) using 'standard position' (feet
          first, arms slightly out). ***5 feet maximum height*** (5ft min
          depth).
        </Added>
        <Added>
          Added: Perform <G id="floating-disconnect">wet rappel disconnect</G>{' '}
          in water.
        </Added>
        <p>
          <Link to={`${route.aca_2018_skills_checklist}#core-skills-checklist`}>
            Previous Core Skills Checklist
          </Link>
          <br />
          <Link to={route.aca_core_skills_checklist}>
            Current Core Skills Checklist
          </Link>
        </p>

        <h2>Aspirant Skills Checklist</h2>
        <Header>Knot Craft</Header>
        <Removed>
          Removed: Tie and inspect; Valdotain tresse (Moved to{' '}
          <Link to={route.aca_core_skills_checklist + '#basic-knots'}>
            Core - Basic Knots
          </Link>
          )
        </Removed>
        <Added>Added: Tie and inspect; Klemheist</Added>
        <Header>Anchors</Header>
        <Removed>
          Removed: Apply <G>sequencing</G> and friction to utilize otherwise
          marginal anchors. (Moved to{' '}
          <Link to={route.aca_cl1_skills_checklist + '#anchors'}>
            CL1 - Anchors
          </Link>
          )
        </Removed>
        <Removed>
          Removed: Construct, evaluate, rig and use{' '}
          <G id="deadman-anchor">dead man anchors</G>.
        </Removed>
        <Removed>
          Removed: Demonstrate how to backup / test a <G>marginal anchor</G>.
          (Moved to{' '}
          <Link to={route.aca_cl1_skills_checklist + '#anchors'}>
            CL1 - Anchors
          </Link>
          )
        </Removed>
        <Added>
          Added: Identify, Evaluate, and rig single-point <G>natural anchor</G>{' '}
          (i.e. tree, boulder, arch,) a). using a cinching wrap -{' '}
          <G>wrap 3 pull 2</G>, b). redundant wrap (i.e. Basket with Overhand
          knot at focal point) Explain the pros and cons and demonstrate how to
          safely test single point anchors
        </Added>
        <Added>
          Added: Rope Retrieval: Mitigate standard rope retrieval challenges
          with "
          <G displayTerm={true} id="courtesy-rigging">
            courtesy loop
          </G>
          ".
        </Added>
        <Added>
          Added: Rope Retrieval: Anchor a partially retrieved rope (
          <G>pull side</G>)
        </Added>
        <Added>
          Added: Rope Retrieval: Ascend a partially retrieved rope (
          <G>rappel side</G>)
        </Added>
        <Header>Rigging</Header>
        <Removed>
          Removed: Identify situations that dictate setting rope length to{' '}
          <G displayTerm={true} id="set-rope-length">
            height of rappel
          </G>
          .
        </Removed>
        <Removed>
          Removed: Lower a person, using <G>hands free backup</G> (i.e.{' '}
          <G>friction hitch</G> attached to the <G>harness</G> of the person in
          control of lowering).
        </Removed>
        <Removed>
          Added: Describe methods for safely setting initial rope lengths for
          wet and dry landings i.e. estimating height,{' '}
          <G displayTerm={true} id="releasable-rigging-system">
            rigging releasable
          </G>
          , lowering first person, above water level.
        </Removed>
        <Added>
          Added: Demonstrate how to safely and efficiently reset / adjust rope
          length.
        </Added>
        <Added>
          Added: Identify and demonstrate how to change abrasion points as
          needed to protect rope/<G>webbing</G> from abrasion and soft rock from
          rope grooves/damage.
        </Added>
        <Header>Belaying</Header>
        <Removed>
          Removed: Demonstrate proper <G>bottom belay</G> (Fireman's belay).
        </Removed>
        <Removed>
          Removed: Set up and use a <G>top-rope belay</G> system.
        </Removed>
        <Removed>
          Removed: Demonstrate proper belay commands / verbal communication
          techniques.
        </Removed>
        <Added>
          Added: Belay a climber; climbing down, a.) using rope from human
          anchor (hip belay) 10ft or less, b.) using rope from fixed anchor.
        </Added>
        <Header>Signals / Communication</Header>
        <p>Didn't exist in previous version</p>
        <Added>Added: Understand and use proper whistle signals.</Added>
        <Added>
          Added: Use Radios to communicate verbal commands for belaying,
          rappelling.
        </Added>
        <Header>On Rope Techniques, Companion Rescue</Header>
        <Removed>
          Removed: Contact Rescue - Rappel on secured rope to provide assistance
          to person stuck on rappel; provide <G>foot loop</G> to free stuck gear
          (Not a <G>pick-off</G>). (Moved to{' '}
          <Link to={route.aca_cl1_skills_checklist + '#emergency-response'}>
            CL1 - Emergency Response
          </Link>
          )
        </Removed>
        <Added>
          Added: Safely lower an incapacitated rappeller via Bottom Belay.
          (Moved from{' '}
          <Link to={route.aca_cl1_skills_checklist + '#core-rope-work'}>
            CL1 - Core Rope Work
          </Link>
          )
        </Added>
        <Added>Added: Rappel with pack securely hanging from harness.</Added>
        <Added>
          Added: Demonstrate safe rappel start off of extended courtesy rigging.
        </Added>
        <Added>
          Added: Safely and effectively <G>zip</G> line gear over/around
          obstacle. Describe scenarios for usage.
        </Added>
        <Added>
          Added: Release <G>contingency block</G> and lower a person, using
          hands free backup (i.e. friction hitch attached to the anchor or to
          the harness of the person in control of lowering; avoiding rope
          grooves in soft rock)
        </Added>
        <Added>
          Added: Perform controlled rappel up to 200'+ adding friction
          mid-rappel; communication - whistles or radios; hanging packs.
        </Added>
        <Header>Canyon Leadership</Header>
        <p>Didn't exist in previous version</p>
        <Added>
          Added: Identify and Assess <G>Flash Flood</G> Risks associated with a
          selected canyon.
        </Added>
        <Added>
          Added: Perform initial gear-up 'Buddy Checks' (<G>Safety check</G>)
          and at 'Buddy Checks' every rappel.
        </Added>
        <Added>
          Added: Canyon Basic First Aid / CPR: a) Stabilize injury / Treat for
          Shock; b) Go for help; c) Basic injury management.
        </Added>
        <Header>Swimming, Jumping, Waterfalls</Header>
        <p>Renamed from "Swimming, Swift Water"</p>
        <Removed>
          Removed: Create and use flotation with gear available. (Moved to{' '}
          <Link
            to={
              route.aca_core_skills_checklist + '#swimming-jumping-waterfalls'
            }
          >
            Core - Swimming, Jumping, Waterfalls
          </Link>
          )
        </Removed>
        <Added>
          Added: Jumping into water (Max distance 8ft./8ft. Min depth) identify
          hazards/depth check, correct body position.
        </Added>
        <Added>
          Added: <G>Rope entanglement</G> hazard
        </Added>
        <p>
          <Link
            to={`${route.aca_2018_skills_checklist}#aspirant-skills-checklist`}
          >
            Previous Aspirant Skills Checklist
          </Link>
          <br />
          <Link to={route.aca_aspirant_skills_checklist}>
            Current Aspirant Skills Checklist
          </Link>
        </p>

        <h2>Canyon Leader 1 Skills Checklist</h2>
        <Header>Anchors</Header>
        <Added>
          Added: Demonstrate how to backup / test a marginal anchor. (Moved from{' '}
          <Link to={route.aca_aspirant_skills_checklist + '#anchors'}>
            Aspirant - Anchors
          </Link>
          )
        </Added>
        <Added>
          Added: Apply sequencing and friction to utilize otherwise marginal
          anchors. (Moved from{' '}
          <Link to={route.aca_aspirant_skills_checklist + '#anchors'}>
            Aspirant - Anchors
          </Link>
          )
        </Added>
        <Header>Rigging</Header>
        <Removed>
          Removed: Rig and use <G>static courtesy rigging</G> to facilitate easy
          rappel starts & rope retrieval. (Redundant - already in Aspirant).
        </Removed>
        <Header>Core Rope Work</Header>
        <Removed>
          Removed: Transition from providing a bottom belay to lowering for an
          incapacitated person who is on rappel. (Moved to{' '}
          <Link
            to={
              route.aca_aspirant_skills_checklist +
              '#on-rope-techniques-companion-rescue'
            }
          >
            Aspirant - On Rope Techniques, Companion Rescue
          </Link>
          )
        </Removed>
        <Removed>
          Removed: Convert static{' '}
          <G displayTerm={true} id="single-rope-rigging">
            single rope rigging
          </G>{' '}
          (e.g. block) to lower. Use hands free backup (i.e. friction hitch)
          when lowering. (Moved to{' '}
          <Link
            to={
              route.aca_cl1_skills_checklist +
              '#on-rope-techniques-teamwork-companion-rescue'
            }
          >
            CL1 - On Rope Techniques, Teamwork, Companion Rescue
          </Link>
          )
        </Removed>
        <Added>
          Added: Set up and use a top-rope belay system a.) from human anchor
          (hip belay) 10ft or less, b.) from fixed anchor.
        </Added>
        <Header>On Rope Techniques, Teamwork, Companion Rescue</Header>
        <p>Renamed from On Rope Techniques, Companion Rescue</p>
        <Removed>
          Removed: Demonstrate a 'soft' rappel start; explain when such a start
          should be used.
        </Removed>
        <Added>
          Added: Convert static single rope rigging (e.g. block) to lower. Use
          hands free backup (i.e. friction hitch) when lowering. (Moved from{' '}
          <Link to={route.aca_cl1_skills_checklist + '#core-rope-work'}>
            CL1 - Core Rope Work
          </Link>
          )
        </Added>
        <Added>
          Added: Demonstrate the following advanced partner capture techniques:
          a) Foot Capture; b) <G>Partner Capture</G>; c){' '}
          <G id="human-pyramid">Human Ladder</G>.
        </Added>
        <Added>
          Added: Perform a Dynamic Belay (Lowering while maintaining belay).
        </Added>
        <Added>
          Added: Perform controlled rappel <G>free hanging</G> and/or up to
          250'+ adding friction mid-rappel; communication - radios; hanging
          packs.
        </Added>
        <Header>Participant Coaching / Teaching</Header>
        <Added>
          Added: Belay a climber; climbing up, a.) using rope from human anchor
          (hip belay) 10ft or less b.) using rope from fixed anchor.
        </Added>
        <Header>Emergency Response</Header>
        <Removed>
          Removed: Personal Locator Beacon (PLB) instruction / usage. (Moved to{' '}
          <Link to={route.aca_core_skills_checklist + '#logistics-and-safety'}>
            Core - Logistics and Safety
          </Link>
          )
        </Removed>
        <Added>
          Added: Contact Rescue - Rappel on secured rope to provide assistance
          to person stuck on rappel; provide foot loop to free stuck gear (Not a
          pick-off). (Moved from{' '}
          <Link
            to={
              route.aca_aspirant_skills_checklist +
              '#on-rope-techniques-companion-rescue'
            }
          >
            Aspirant - On Rope Techniques, Companion Rescue
          </Link>
          )
        </Added>
        <Header>Canyon Leadership</Header>
        <p>New section for the 2020 version</p>
        <Added>
          Added: Weather – interpret Radar, Water Vapor, & Infrared Satellite
          Imagery
        </Added>
        <Added>
          Added: Perform Pack Checks (for selected canyon) – check for
          appropriate: Water; Food; Clothing; Footwear; Equipment; First Aid
          Supplies; Technical eBag (extra <G>hooks</G>, webbing, <G>Rapides</G>,{' '}
          <G>Potshot</G>, etc.).
        </Added>
        <Added>
          Added: Model / Teach: basic <G>bridging</G>; <G>stemming</G>;{' '}
          <G>chimneying</G>.
        </Added>
        <Added>
          Added: Manage participant fearful of rappelling (from bottom).
        </Added>
        <Added>
          Added: Set Safe zones / gates – move group from safe zone to safe zone
          (keep group together).
        </Added>
        <Added>Added: Recognize and treat symptoms of Hypothermia.</Added>
        <Added>Added: Recognize and treat symptoms of Hyperthermia.</Added>
        <Added>
          Added: Wilderness First Aid - perform: a) Splint a lower leg injury;
          b) Stabilize & Horizontal Evacuation.
        </Added>
        <Header>Specialized Skills for Class A/B Canyons</Header>
        <Removed>
          Removed Class{' '}
          <G displayTerm={true} id="a-water-rating">
            A
          </G>
          /
          <G displayTerm={true} id="b-water-rating">
            B
          </G>{' '}
          Canyons — Core Skills section (it moved to On Rope Techniques,
          Teamwork, Companion Rescue). It had the following:
          <p />
          <ul>
            <li>Down Climbing / Stemming ability.</li>
            <li>
              Big Rappels (Free hanging and/or 250'+) - adding friction
              mid-rappel; communication - radios; hanging packs
            </li>
            <li>Advanced partner captures</li>
          </ul>
        </Removed>
        <Header>Specialized Skills for Class C Canyons</Header>
        <Added>
          Added: Jumping into water (Max distance 10ft./10ft. Min depth)
          identify hazards/depth, correct body position ("Pencil" technique).
        </Added>
        <Added>
          Added: Cut self off of entangled rope in water (10 sec max).
        </Added>
        <Added>Added: Swim for 4 minutes without floatation.</Added>
        <p>
          <Link
            to={`${route.aca_2018_skills_checklist}#canyon-leader-1-skills-checklist`}
          >
            Previous Canyon Leader 1 Skills Checklist
          </Link>
          <br />
          <Link to={route.aca_cl1_skills_checklist}>
            Current Canyon Leader 1 Skills Checklist
          </Link>
        </p>

        <h2>Canyon Leader 2 Skills Checklist</h2>
        <Header>Anchors</Header>
        <Added>
          Added: Perform controlled rappel free hanging and/or up to 300'+ -
          Adding friction mid-rappel; communication - radios; hanging packs.
        </Added>
        <Header>Canyon Leadership</Header>
        <li>New section for the 2020 version</li>
        <Added>
          Added: Give initial safety briefing which includes: a)
          Medical/pre-existing conditions; b) Weather; c) PLB usage d) First Aid
          Kit location; e) <G>Egress</G> plan.
        </Added>
        <Added>
          Added: Manage participant fearful of rappelling (from top).
        </Added>
        <Added>
          Added: Deal with participant fearful of down climbing / exposed
          traversing.
        </Added>
        <Added>
          Added: Give pertinent/timely in-canyon safety/technical instruction
          for task at hand: a) Downclimbing; b) Rappelling; c) Belaying d)
          Partner Capture; e) Marginal Anchors; f) Swimming.
        </Added>
        <Added>
          Added: Wilderness First Aid - perform: a) Immobilize potential
          back/neck injury (do not move); b) Stabilize patient, minor injury &
          Vertical Evacuation.
        </Added>
        <Header>Class A/B Canyons — Pothole Escape</Header>
        <p>New section for the 2020 version</p>
        <Added>
          Added: Escaping a <G>pothole</G> using{' '}
          <G displayTerm={true} id="pothole-escape-technique">
            counter-weight techniques
          </G>
          . Deploy counter weights - <G>Pack toss</G> and/or Pot Shots
        </Added>
        <Added>
          Added: Escaping a pothole using counter-weight techniques. Safely
          ascend multiple ropes simultaneously.
        </Added>
        <Added>
          Added: Escaping a pothole using counter-weight techniques - Pass the
          pothole lip out.
        </Added>
        <Header>Class C Canyons — Core Skills</Header>
        <p>New section for the 2020 version</p>
        <Added>
          Added: Jump into pool and moving water, 10 ft max identify
          hazards/depth, correct body position.
        </Added>
        <Added>Added: Swim for 6 minutes without floatation.</Added>
        <Added>Added: Hold breath for 20 seconds underwater.</Added>
        <p>
          <Link
            to={`${route.aca_2018_skills_checklist}#canyon-leader-2-skills-checklist`}
          >
            Previous Canyon Leader 2 Skills Checklist
          </Link>
          <br />
          <Link to={route.aca_cl2_skills_checklist}>
            Current Canyon Leader 2 Skills Checklist
          </Link>
        </p>

        <h3>Notes</h3>
        <p>
          There were lots of other smaller changes that I'm not including
          because I'm not <em>that</em> nerdy. Well I am, actually. If you want
          to know them all like me then google "diff 2 files", navigate to one
          of the pages, and you can view the line-by-line differences between
          files. <Link to={allLinks.skillsChecklistTxt}>Here</Link> are a bunch
          of text files of all the skills checklists.
        </p>
        <p>Finally, some words from Rick Green, ACA President:</p>
        <blockquote>
          Everyone should know that these lists are an ongoing project. Despite
          contributions from many very knowledgeable people, use of relatively
          current information and our best efforts, undoubtedly there will be
          many errors. Let me know if there are items that you feel need
          adjustment, changed or will be helpful to have placed in better
          context.
        </blockquote>
      </Layout>
    );
  }
}

// noinspection JSUnusedGlobalSymbols
export default withFrontMatter('2020-04-26-aca-skills-checklists-updates')(
  Blog_2020_04_25_Aca_Skills_Checklists_Updates
);
